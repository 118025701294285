<template>
  <div class="page" style="margin:0">
    <div class="flex-r-s-c" style="padding-top:0.3rem;margin-left:0.2rem" @click="$utils.pathGoBack(-1)">
      <van-icon name="arrow-left" style="font-size: 0.57rem;" />
    </div>
    <div class="login_form flex-c-c-c" style="margin-top:0;padding-top:0.32rem">
      <van-image width="1.33rem" height="1.33rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_logo_szxq.png" />
      <div class="login_form_title">请完善个人资料</div>
      <van-form @submit="onSubmit">
        <van-field v-model="loginForm.companyName" type="text" name="companyName" placeholder="请输入您的公司名称"
                   :rules="[{ required: true, message: '' }]"
        />
        <van-field readonly clickable :value="loginForm.districtName" placeholder="请选择您的所在地区" right-icon="arrow"
                   @click="showPicker = true"
        />
        <div class="login_sub">
          <van-button round block :loading="loading" type="info" loading-text="注册中..." native-type="submit"
                      color="#0767ab" style="font-size: 0.43rem;"
          >进入商城
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import { districts } from '@/api/public'
import { userInfo_update } from '@/api/user'
export default {
  name: 'RegisterStep02',
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      isSend: false,
      loading: false,
      time: 60 * 1000,
      showPicker: false,
      cascadervalue: '',
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      loginForm: {
        mobile: '', checkCode: '', openId: '', districtName: '', platformType: 'wxmp' // 微信(H5):wxmp 微信:wx
      }
    }
  },
  created() {
    if (localStorage.getItem('openId') || this.$store.getters.openid) {
      this.loginForm.openId = localStorage.getItem('openId') || this.$store.getters.openid
    }
    this.loadDistrict()
  },
  methods: {
    // 点击登录
    onSubmit() {
      this.loginForm.newCategoryId = 1
      this.loginForm.reLogin = true
      this.loading = true
      userInfo_update(this.loginForm).then(res => {
        localStorage.removeItem('token')
        this.$toast({ message: '补充成功！', duration: 3 * 1000 })
        res.data.openid = this.openid
        this.$utils.storeUserInfo(this.$store, res.data)
        this.loading = false
        this.$utils.localLogin(this, res.data, 'login_pet')
        this.$nextTick(() => { sessionStorage.setItem('liveFlag', 1); sessionStorage.setItem('liveFlag_cart', 1) })
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 省市区
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.loginForm.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.loginForm.provinceId = value.selectedOptions[0].value
      this.loginForm.cityId = value.selectedOptions[1].value
      this.loginForm.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() {
      this.showPicker = false
    }
  }
}
</script>
